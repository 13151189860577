h1, h2 {
  margin-left: 0;
}

#pageContent {
  max-width: 80em;
  margin: auto;
}

.tools li {
  max-width: 30em;
  list-style-type: none;
  display: inline-block;
}

.tools {
  column-count: 2;
}

/*# sourceMappingURL=index.cd0e5e8f.css.map */
